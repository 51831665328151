import React, { Component } from 'react';
import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import TestimFamily from '../../images/testimfamily.png';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const dotsSettings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    focusOnSelect: true,
};
class WhatParentsSay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
    }

    render() {
        return (
            <section className="page-section bg-secondary text-white testimsec">
                <h2 className="yellowtxt">What Parents Say</h2>
                <div
                    id="myCarousel"
                    className="carousel slide"
                    data-ride="carousel"
                >
                    <Slider
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                        {...dotsSettings}
                        className="carousel-indicators"
                    >
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </Slider>
                    <Slider
                        {...settings}
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                        className="carousel-inner"
                    >
                        <div className="item carousel-item active">
                            <p className="testimonial">
                                “We had our first ever birthday party for our five year old today. Our son has sensory needs and ADHD, so we loved the active play in a setting that wasn’t overwhelming. We rented the entire place for two hours and he had a blast with his friends and family! Huge HUGE shoutout to staffers Salma and Sarah for being there to help set up and clean up. They were so kind and thoughtful, and great with our birthday boy! Thank you for giving our son a birthday party that wasn’t two thumbs up- it was both hands up (his review)!”
                            </p>
                            <p className="overview">Gina H.</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “We celebrated my sons 7th birthday party and we used the “Rock Unique” package and it was absolutely perfect! We brought all our own paper goods etc and the two women working set up the party room and made everything so easy for us. Truly a great space for all different kinds of kiddos and there is something for everyone to enjoy. Will recommend to friends in the future and will be back for playing!”
                            </p>
                            <p className="overview">Anne K.</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “MY CHILDREN AND I LOVE THIS PLACE AND THE STAFF ARE ALWAYS WONDERFUL TOWARD US RATHER WE ARE ATTENDING , OPEN GYM OR PLANNING A BIRTHDAY ITS ALWAYS A GREAT TIME !"
                            </p>
                            <p className="overview">
                                Tender S.
                            </p>
                        </div>

                        <div className="item carousel-item">
                            <p className="testimonial">
                                “I’m sooooo late writing this review but I absolutely had to I had both of my children birthday party here together in October 2023 and it was perfect 10/10 Taylor and the other lady (I forgot her name) did not let me lift a finger. They got the decorations out of the car set it up and the food and also served! They took everything down packed it up and loaded everything back into my car. They both went above and beyond! Now the children had so much fun! From the little babies to the older kids. This place is a kids dream they can run, jump, swing, slide, and even glide on the zip line. 10/10 definitely recommend!"
                            </p>
                            <p className="overview">
                                Ronnie L.
                            </p>
                        </div>

                        <div className="item carousel-item">
                            <p className="testimonial">
                                “Hosted a birthday party for our 2 year old here with kids ages 1-7 and everyone had a blast! Angela and Taylor are super sweet and accommodating. We will definitely be back :)"
                            </p>
                            <p className="overview">
                                Hope C.
                            </p>
                        </div>
                    </Slider>
                    <img
                        className="quoteimg"
                        src={TestimFamily}
                        alt="Testimonials family"
                    />
                </div>
            </section>
        );
    }
}

export default WhatParentsSay;
